<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :loader="$store.state.spinerLoader"
      :color="$store.state.spinerColor"
    ></loading>
    <vue-snotify></vue-snotify>
    <CCard class="card-border">
      <div
        class="rest-bg-imge"
        v-bind:style="{ backgroundImage: 'url(' + menuBackgroundImage + ')' }"
      >
        <div class="resturent-main-card">
         
          <div class="row">
            <div class="col-md-3 col-sm-3 col-3">
              <h1 class="font-family text-white ml-1">Menu</h1>
            </div>
            <div class="col-md-9 col-sm-9 col-9">
              <div class="row">
                <div class="col-md-10 col-10 mt-2">
                  <select class="">
                    <option>Meal</option>
                    <option>Breakfast</option>
                    <option>Lunch</option>
                    <option>Evening</option>
                    <option>Dinner</option>
                  </select>
                  <select class="">
                    <option>Method</option>
                    <option>PickUp</option>
                    <option>Dilivery</option>
                  </select>
                </div>
                <div class="col-md-2 col-2 mt-2">
                  <button class="btn btn-sm btn-success float-right mr-1">
                    <CIcon color="green" name="cil-phone" title="call waiter" />
                  </button>

                  <!-- <CButton
                    color="primary"
                    class="float-right"
                    @click="
                      $router.push({
                        name: 'RestaurantBookingForm',
                        params: { tenant_id: tenant_id },
                      })
                    "
                    >Profile</CButton
                  > -->
                </div>
              </div>

              <!-- <div class="pl-3 pr-3 meal-plan">
                <CButton
                  :color="getDynamicColor('1')"
                  @click="setActivebtn('1')"
                  shape="pill"
                  class="mb-1"
                  >Breakfast</CButton
                >
                <CButton
                  :color="getDynamicColor('2')"
                  @click="setActivebtn('2')"
                  shape="pill"
                  class="ml-3 mb-1"
                  >Lunch</CButton
                >
                <CButton
                  :color="getDynamicColor('3')"
                  @click="setActivebtn('3')"
                  shape="pill"
                  class="ml-3 mb-1"
                  >Evening</CButton
                >
                <CButton
                  :color="getDynamicColor('4')"
                  @click="setActivebtn('4')"
                  shape="pill"
                  class="ml-3 mb-1"
                  >Dinner</CButton
                >
              </div> -->
            </div>
          </div>

          <hr class="dashed custom-dashed" />

          <CRow>
            <CCol md="12">
              <div class="pl-3 pb-3 pr-3 meal-plan">
                <CButton
                  v-if="categoryItem.menu_items.length"
                  v-scroll-to="{
                    el: '#category_div_' + categoryItem.id + '',
                    container: '#scrolling-wrapper',
                    duration: 500,
                    lazy: false,
                    offset: -30,
                  }"
                  :color="getDynamicCategoryBtnColor(categoryItem.id)"
                  class="ml-1 sub-category-btn mb-1"
                  v-for="(categoryItem, index) in restaurantMenuCategory"
                  shape="pill"
                  @click="setActiveMenuCategoryBtn(categoryItem.id)"
                  >{{ categoryItem.title }}</CButton
                >
              </div>
            </CCol>
          </CRow>

          <div
            class="menu-container-scroll"
            v-if="restaurantMenuCategory && restaurantMenuCategory.length"
          >
            <div
              class="menu-container"
              v-for="(categoryItem, index) in restaurantMenuCategory"
              :key="index"
              :id="getDynamimcCategoryDIVRef(categoryItem.id)"
            >
              <div
                class="row"
                v-if="categoryItem && categoryItem.menu_items.length"
              >
                <div class="col-md-9 col-9">
                  <div class="mt-2 ml-2">
                    <b
                      >{{ categoryItem.title }} - ({{
                        categoryItem.menu_items.length
                      }})</b
                    >
                  </div>
                </div>
                <div class="col-md-3 col-3">
                  <div class="mt-2 text-info see-all-link float-right mr-2">See All</div>
                </div>
                <div
                  class="col-md-6 col-sm-12"
                  v-for="(item, index2) in categoryItem.menu_items"
                  :key="index2"
                >
                  <div
                    class="card custom-menu-card"
                    @click="openMenuItemModal(item.item)"
                  >
                    <div class="row">
                      <div class="col-md-12 col-sm-12">
                        <div class="row">
                          <div class="col-md-5 col-sm-5 col-xs-5 col-5">
                            <CImg
                              v-if="item.item.image_collection[0]"
                              class="custom-menu-img"
                              :src="item.item.image_collection[0].url"
                            ></CImg>
                          </div>
                          <div class="col-md-7 col-sm-7 col-7">
                            <div class="row">
                              <div class="col-md-12 col-sm-12">
                                <div class="pt-2">
                                  <h6 class="mb-0">
                                    <b>{{
                                      item.item.title | truncate(25, "...")
                                    }}</b>

                                    <p class="menu-item-description-txt">{{
                                      item.item.description | truncate(40, "...")
                                    }}</p>

                                    <CBadge
                                      v-if="categoryItem.billing"
                                      class="ml-2 p-1"
                                      color="success"
                                      >{{
                                        item.item.atr_discount
                                          ? item.item.atr_discount + "% Off"
                                          : ""
                                      }}</CBadge
                                    >
                                  </h6>
                                  <h6
                                    class=""
                                    v-if="
                                      item.item.atr_discount &&
                                      categoryItem.billing
                                    "
                                  >
                                    <del class="mr-2">{{
                                      item.item.atr_price | currency("Rs ")
                                    }}</del>
                                    <span class="menu-item-price-txt">{{
                                      (item.item.atr_price -
                                        (item.item.atr_discount *
                                          item.item.atr_price) /
                                          100)
                                        | currency("Rs ")
                                    }}</span>
                                  </h6>
                                  <h6
                                    class="menu-item-price-txt"
                                    v-if="
                                      !item.item.atr_discount &&
                                      categoryItem.billing
                                    "
                                  >
                                    {{ item.item.atr_price | currency("Rs ") }}
                                  </h6>
                                  <h6 v-if="!categoryItem.billing"></h6>
                                </div>
                              </div>

                              <div class="col-md-12 col-sm-12">
                                <div
                                  class="float-left"
                                  v-for="(itemVal, index) in item.item
                                    .item_attributes"
                                  :key="index"
                                >
                                  <CButton
                                    class="varient-btns"
                                    v-if="indexAtr <= 2 && atrItem.status == 1"
                                    v-for="(
                                      atrItem, indexAtr
                                    ) in itemVal.attribute_values"
                                    :key="indexAtr"
                                    color="primary"
                                    size="sm"
                                    @click.stop.prevent="
                                      selectVariant(atrItem, item.item)
                                    "
                                    :variant="
                                      atrItem.selected == 1 ||
                                      (atrItem.selected == undefined &&
                                        indexAtr == 0 &&
                                        index == 0)
                                        ? ''
                                        : 'outline'
                                    "
                                    >{{ atrItem.attribute_value.name }}</CButton
                                  >
                                </div>

                                <div class="float-right pr-3 pb-3">
                                  <CButton
                                    size="sm"
                                    color="primary"
                                    @click.stop.prevent="
                                      checkOrderModalShow();
                                      setMenuItem(item.item);
                                    "
                                  >
                                    <CIcon name="cil-plus" />
                                  </CButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style="display: none"
            id="scrolling-wrapper"
            class="food-item-scroll"
          >
            <div>
              <div
                v-if="categoryItem.menu_items.length"
                class="w-100 food-row"
                v-for="(categoryItem, index) in restaurantMenuCategory"
                :id="getDynamimcCategoryDIVRef(categoryItem.id)"
              >
                <CCol md="12">
                  <CAlert class="category-color mt-1">
                    <b
                      >{{ categoryItem.title }} - ({{
                        categoryItem.menu_items.length
                      }})</b
                    >
                    <CIcon class="ml-2 success" name="cilChevronDoubleRight" />
                  </CAlert>
                </CCol>
                <div class="scrolling-wrapper">
                  <div
                    v-for="(item, index) in categoryItem.menu_items"
                    class="card-item"
                  >
                    <CCard
                      class="min-height-card"
                      @click="openMenuItemModal(item.item)"
                    >
                      <CRow>
                        <CCol md="12">
                          <CImg
                            v-if="item.item.image_collection[0]"
                            class="restaurant-img"
                            :src="item.item.image_collection[0].url"
                          ></CImg>
                        </CCol>
                        <CCol md="12">
                          <CRow class="pl-2 pb-3 pr-2">
                            <CCol md="12" sm="12">
                              <div class="pt-2">
                                <h6 id="traffic" class="card-title mb-0">
                                  <b>{{
                                    item.item.title | truncate(25, "...")
                                  }}</b>

                                  <CBadge
                                    v-if="categoryItem.billing"
                                    class="ml-2 p-1"
                                    color="success"
                                    >{{
                                      item.item.atr_discount
                                        ? item.item.atr_discount + "% Off"
                                        : ""
                                    }}</CBadge
                                  >
                                </h6>
                                <h6
                                  class="card-title mb-0"
                                  v-if="
                                    item.item.atr_discount &&
                                    categoryItem.billing
                                  "
                                >
                                  <del class="mr-2">{{
                                    item.item.atr_price | currency("Rs ")
                                  }}</del>
                                  <span>{{
                                    (item.item.atr_price -
                                      (item.item.atr_discount *
                                        item.item.atr_price) /
                                        100)
                                      | currency("Rs ")
                                  }}</span>
                                </h6>
                                <h6
                                  class="card-title mb-0"
                                  v-if="
                                    !item.item.atr_discount &&
                                    categoryItem.billing
                                  "
                                >
                                  {{ item.item.atr_price | currency("Rs ") }}
                                </h6>
                                <h6 v-if="!categoryItem.billing"></h6>
                                <!-- <div class="small discount-text">
                                                                   
                                                                </div> -->
                              </div>
                            </CCol>
                          </CRow>
                        </CCol>
                        <CCol md="12">
                          <div class="">
                            <div
                              class="float-left pl-2 pb-3"
                              v-for="(itemVal, index) in item.item
                                .item_attributes"
                              :key="index"
                            >
                              <CButton
                                class="ml-1"
                                v-if="indexAtr <= 2 && atrItem.status == 1"
                                v-for="(
                                  atrItem, indexAtr
                                ) in itemVal.attribute_values"
                                :key="indexAtr"
                                color="light"
                                size="sm"
                                @click.stop.prevent="
                                  selectVariant(atrItem, item.item)
                                "
                                :variant="
                                  atrItem.selected == 1 ||
                                  (atrItem.selected == undefined &&
                                    indexAtr == 0 &&
                                    index == 0)
                                    ? ''
                                    : 'outline'
                                "
                                >{{ atrItem.attribute_value.name }}</CButton
                              >
                            </div>

                            <div
                              class="float-right pr-3 pb-3"
                              v-if="customer_access_token"
                            >
                              <CButton
                                v-if="
                                  hasPermission('CUSTOMER_ORDER') &&
                                  hasPermission(tenant_id) &&
                                  active_reservation_id
                                "
                                size="sm"
                                color="primary"
                                @click.stop.prevent="
                                  checkOrderModalShow();
                                  setMenuItem(item.item);
                                "
                                >Order</CButton
                              >
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                    </CCard>
                  </div>
                </div>
              </div>
              <div class="w-100 empty-food-row"></div>
              <div class="mobile-space"></div>
            </div>
            <CRow>
              <CCol md="12" v-if="restaurantMenuCategory.length == 0">
                <CAlert class="text-center" color="warning"
                  >Sorry!!! , We have no any items for this meal.</CAlert
                >
              </CCol>
            </CRow>
          </div>
        </div>
      </div>
    </CCard>

    <!-- Order Modal -->
    <CModal
      :show.sync="showOrderModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol md="12">
          <p
            v-if="
              selectedMenuItem.item_attributes &&
              selectedMenuItem.item_attributes.length
            "
            class="text-white"
          >
            Choice of Size
          </p>
          <div
            class="float-left pb-3"
            v-for="(item, index) in selectedMenuItem.item_attributes"
          >
            <CButton
              v-if="atrItem.status == 1"
              class="ml-1"
              v-for="(atrItem, indexAtr) in item.attribute_values"
              color="primary"
              size="md"
              @click="selectVariant(atrItem, selectedMenuItem)"
              :variant="active_variation.id == atrItem.id ? '' : 'outline'"
              >{{ atrItem.attribute_value.name }}</CButton
            >
          </div>

          <div class="float-right">
            <p class="text-white">
              <b>
                <span v-if="selectedMenuItem.discount">
                  <del class="mr-2">{{
                    selectedMenuItem.price | currency("Rs ")
                  }}</del>
                  <span>{{
                    (selectedMenuItem.price -
                      (selectedMenuItem.discount * selectedMenuItem.price) /
                        100)
                      | currency("Rs ")
                  }}</span>
                </span>
                <span v-if="!selectedMenuItem.discount">
                  {{ selectedMenuItem.price | currency("Rs ") }}
                </span>
              </b>
            </p>
          </div>
        </CCol>
        <CCol md="12">
          <CTextarea
            class="text-white"
            label="Special Note"
            placeholder="Type here..."
            rows="4"
            v-model="order_comment"
          />
        </CCol>
        <CCol md="12">
          <CButton
            color="light"
            size="md"
            variant="outline"
            @click="decrementMenuItemCount"
            >-</CButton
          >
          <CButton color="light" size="md" disabled class="ml-1">{{
            menuItemCount
          }}</CButton>
          <CButton
            color="light"
            size="md"
            variant="outline"
            class="ml-1"
            @click="incrementMenuItemCount"
            >+</CButton
          >
        </CCol>
      </CRow>

      <template #header>
        <h6 class="modal-title">{{ selectedMenuItem.title }}</h6>
        <CButtonClose @click="showOrderModal = false" class="text-white" />
      </template>
      <template #footer>
        <span
          @click="
            showOrderModal = false;
            addTocart();
          "
          color="primary"
          class="text-white"
          >Add {{ menuItemCount }} to Order (

          <span v-if="selectedMenuItem.discount">
            <del class="mr-2">{{
              (selectedMenuItem.price * menuItemCount) | currency("Rs ")
            }}</del>
            <span>{{
              ((selectedMenuItem.price -
                (selectedMenuItem.discount * selectedMenuItem.price) / 100) *
                menuItemCount)
                | currency("Rs ")
            }}</span>
          </span>
          <span v-if="!selectedMenuItem.discount">
            {{ (selectedMenuItem.price * menuItemCount) | currency("Rs ") }}
          </span>

          )</span
        >
        <CButton @click="showOrderModal = false" variant="outline" color="light"
          >Close</CButton
        >
        <CButton
          @click="
            showOrderModal = false;
            confirmOrderItem();
          "
          color="primary"
        >
          Confirm Order <CIcon name="cil-check" />
        </CButton>
      </template>
    </CModal>

    <!-- Order Modal -->
    <CModal
      :show.sync="foodDetailsModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
      addContentClasses="mr-3"
    >
      <div class="text-white">
        <table class="table table-striped table-dark">
          <tr>
            <td class="thead-dark"><label>Title </label></td>
            <td>{{ viewMenuItem.title }}</td>
          </tr>

          <tr>
            <td><label>Price </label></td>
            <td>{{ viewMenuItem.price | currency("Rs ") }}</td>
          </tr>

          <tr>
            <td><label>Description </label></td>
            <td>{{ viewMenuItem.description }}</td>
          </tr>

          <tr>
            <td><label>Discount % </label></td>
            <td>{{ viewMenuItem.discount ? viewMenuItem.discount : "N/A" }}</td>
          </tr>
        </table>
      </div>

      <template #header>
        <h6 class="modal-title">{{ viewMenuItem.title }}</h6>
        <CButtonClose @click="foodDetailsModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton
          @click="foodDetailsModal = false"
          variant="outline"
          color="light"
          >Close</CButton
        >
      </template>
    </CModal>

    <!-- Validate reservation number modal -->
    <CModal
      :show.sync="validateReservationNumberModal"
      :closeOnBackdrop="false"
      :centered="true"
      title=""
      size="lg"
      color="dark"
      addContentClasses="mr-3"
    >
      <CRow>
        <CCol md="12" class="m-3">
          <div class="form-group">
            <CInputRadioGroup
              class="text-white"
              :options="[
                {
                  value: 'Yes',
                  label: '(YES) ',
                  props: { description: '  If you have reservation number ' },
                },
                {
                  value: 'No',
                  label: '(NO) ',
                  props: {
                    description: '   If you do not have reservation number ',
                  },
                },
              ]"
              @update:checked="reservationChecked"
              inline
              :checked="radioValidate"
            />
          </div>
        </CCol>
      </CRow>

      <div v-if="radioValidate == 'Yes'">
        <ValidationObserver ref="reservation_number_checking_form">
          <ValidationProvider
            vid="reservation_number"
            name="Reservation Number"
            v-slot="{ errors }"
            rules="required"
          >
            <CRow>
              <CCol md="4" class="mt-4">
                <label class="text-white">Enter Your Reservation Number</label>
              </CCol>
              <CCol md="8">
                <!-- <v-otp-input
                  inputClasses="otp-input"
                  :numInputs="4"
                  separator="-"
                  :shouldAutoFocus="true"
                  @on-complete="handleOnComplete"
                /> -->
                <br />
                <input
                  class="text-center otp-txt-box"
                  type="text"
                  v-mask="'#-#-#-#'"
                  v-model="otp_mask"
                  placeholder="-  -  -  -"
                />

                <div v-if="errors[0]" class="wizard-error">
                  <span v-for="(error, index) in errors" v-bind:key="index">
                    {{ error }}
                  </span>
                </div>
              </CCol>
              <CCol col="4"> </CCol>
              <CCol col="8"> </CCol>
            </CRow>
          </ValidationProvider>
        </ValidationObserver>
      </div>
      <div v-if="radioValidate == 'No'">
        <div v-if="check_table_availability == true">
          <div v-if="customer_access_token && !reservation_id">
            <CButton @click="makeNewReservation" color="primary"
              >Make an On-time Reservation</CButton
            >
          </div>

          <form-wizard
            :start-index="0"
            ref="wizard"
            title=""
            subtitle=""
            color="#7F30BF"
            v-show="!customer_access_token"
            class="custom-wizard-menu"
          >
            <tab-content title="Personal details" :before-change="genereateOtp">
              <ValidationObserver ref="step1_form">
                <div v-show="mobile_show">
                  <CRow>
                    <CCol md="4">
                      <label class="text-white"
                        >Do you have ScanMe account?</label
                      >
                    </CCol>
                    <CCol md="8">
                      <ValidationProvider
                        vid="account_exists"
                        name="ScanMe account exist"
                        v-slot="{ errors }"
                        rules=""
                      >
                        <CSwitch
                          labelOn="Yes"
                          labelOff="No"
                          @update:checked="accountExists"
                          :checked.sync="account_exists"
                          color="primary"
                          class="mb-2"
                        />
                        <input type="hidden" v-model="account_exists" />
                        <div v-if="errors[0]" class="wizard-error mt-2 mb-3">
                          <span
                            v-for="(error, index) in errors"
                            v-bind:key="index"
                          >
                            {{ error }}
                          </span>
                        </div>
                      </ValidationProvider>
                    </CCol>
                  </CRow>

                  <ValidationProvider
                    vid="mobile_no"
                    name="mobile no"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <CRow>
                      <CCol md="4">
                        <label class="sign-up-lable text-white"
                          >Enter your mobile no (required)</label
                        >
                      </CCol>
                      <CCol md="8">
                        <CInput
                          size="lg"
                          v-model="form_params.mobile_no"
                          v-mask="'+94#########'"
                          placeholder="(+94)-XXXXXXXXX"
                          :is-valid="checkValidInput(errors)"
                        />

                        <div v-if="errors[0]" class="wizard-error">
                          <span
                            v-for="(error, index) in errors"
                            v-bind:key="index"
                          >
                            {{ error }}
                          </span>
                        </div>
                      </CCol>
                    </CRow>
                  </ValidationProvider>
                  <CRow v-if="customerAccountExist == 'No'">
                    <CCol md="6">
                      <div class="form-group">
                        <ValidationProvider
                          vid="first_name"
                          name="first name"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <label class="sign-up-lable text-white"
                            >Enter your first name (required)</label
                          >
                          <CInput
                            size="lg"
                            placeholder="First Name"
                            v-model="first_name"
                            :is-valid="checkValidInput(errors)"
                          />
                          <div v-if="errors[0]" class="wizard-error">
                            <span
                              v-for="(error, index) in errors"
                              v-bind:key="index"
                            >
                              {{ error }}
                            </span>
                          </div>
                        </ValidationProvider>
                      </div>
                    </CCol>
                    <CCol md="6">
                      <div class="form-group">
                        <ValidationProvider
                          vid="last_name"
                          name="last name"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <label class="sign-up-lable text-white"
                            >Enter your last name (required)</label
                          >
                          <CInput
                            size="lg"
                            placeholder="Last Name"
                            v-model="last_name"
                            :is-valid="checkValidInput(errors)"
                          />
                          <div v-if="errors[0]" class="wizard-error">
                            <span
                              v-for="(error, index) in errors"
                              v-bind:key="index"
                            >
                              {{ error }}
                            </span>
                          </div>
                        </ValidationProvider>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </ValidationObserver>

              <div v-show="opt_show">
                <ValidationObserver ref="step1_checking_form">
                  <ValidationProvider
                    vid="otp"
                    name="otp"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <CRow>
                      <CCol md="4">
                        <label class="text-white"
                          >Enter the code sent to
                          {{ form_params.mobile_no }}</label
                        >
                      </CCol>
                      <CCol md="4">
                        <input type="hidden" v-model="form_params.otp" />
                        <!-- <v-otp-input
                          inputClasses="otp-input"
                          :numInputs="4"
                          separator="-"
                          :shouldAutoFocus="true"
                          @on-complete="handleOnCompleteRegisterCmp"
                        /> -->
                        <br />

                        <input
                          class="text-center otp-txt-box"
                          type="text"
                          v-mask="'#-#-#-#'"
                          v-model="otp_reservation_mask"
                        />
                        <div v-if="errors[0]" class="wizard-error">
                          <span
                            v-for="(error, index) in errors"
                            v-bind:key="index"
                          >
                            {{ error }}
                          </span>
                        </div>
                      </CCol>
                      <CCol md="4">
                        <div class="text-white text-center" v-show="opt_show">
                          Time Remaining - {{ prettyTime }}
                        </div>
                        <div class="text-center">
                          <a
                            v-show="opt_show"
                            class="location-color"
                            href="#"
                            @click="resendOtp"
                            >Resend otp</a
                          >
                        </div>
                      </CCol>
                    </CRow>
                  </ValidationProvider>
                </ValidationObserver>
              </div>
            </tab-content>
            <tab-content
              title="Additional Info"
              :before-change="checkInfoValidity"
            >
              <ValidationObserver ref="step2_form">
                <div class="form-group">
                  <ValidationProvider
                    vid="email"
                    name="email"
                    v-slot="{ errors }"
                    rules=""
                  >
                    <label class="sign-up-lable">Enter your Email</label>
                    <CInput
                      size="lg"
                      placeholder="Email"
                      v-model="form_params.email"
                      :is-valid="checkValidInput(errors)"
                    />
                    <div v-if="errors[0]" class="wizard-error">
                      <span v-for="(error, index) in errors" v-bind:key="index">
                        {{ error }}
                      </span>
                    </div>
                  </ValidationProvider>
                </div>
              </ValidationObserver>
            </tab-content>

            <template slot="footer" scope="props">
              <div class="wizard-footer-left">
                <!-- remove previous button -->
                <!-- <wizard-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</wizard-button> -->
              </div>
              <div class="wizard-footer-right" v-show="!isTimerRunning">
                <wizard-button
                  @click.native="props.nextTab()"
                  class="wizard-footer-right finish-button"
                  :style="props.fillButtonStyle"
                  >{{ props.isLastStep ? "Done" : "Next" }}</wizard-button
                >
              </div>
            </template>
          </form-wizard>
        </div>
        <div
          v-if="
            check_table_availability == false &&
            check_table_availability_finish == true &&
            !reservation_id
          "
        >
          <CAlert color="warning"
            >Sorry.This table is not available at this time.</CAlert
          >
        </div>
      </div>

      <CRow v-if="reservation_id">
        <CCol class="mt-3">
          <CAlert color="warning"
            >Your reservation is processing and the Reservation PIN will be sent
            shortly.</CAlert
          >
        </CCol>
      </CRow>

      <template #header>
        <h6 class="modal-title">Do you have reservation number ?</h6>
      </template>
      <template #footer>
        <label></label>
      </template>
    </CModal>

    <CModal
      title="Please Rate Us"
      :show.sync="orderRatingModal"
      size="md"
      :closeOnBackdrop="false"
    >
      <CRow class="text-center">
        <CCol md="12">
          <p class="text-white">
            Please help us in bettering our service and website.
          </p>
          <star-rating
            :show-rating="show_rating"
            active-color="#3f2252"
            v-model="rating"
          ></star-rating>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          @click="
            orderRatingModal = false;
            rateRestaurant();
          "
          variant="outline"
          color="primary"
          >Submit</CButton
        >
        <CButton
          @click="
            orderRatingModal = false;
            redirectToMyOrders();
          "
          variant="outline"
          color="dark"
          >Not Now</CButton
        >
      </template>
      <template #header>
        <h6 class="text-white text-center text-rating-bold">
          Please rate the qulaity of our restaurant and services.
        </h6>
      </template>
    </CModal>
  </div>
</template>

<script>
import RestaurantMenuCard from "@/component/RestaurantMenuCard/RestaurantMenuCard.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import commonMixin from "@/mixins/common";
import store from "../../store/store";
import StarRating from "vue-star-rating";
import Echo from "laravel-echo";
window.Pusher = require("pusher-js");
export default {
  name: "RestaurantMenuViewDev1",
  components: {
    RestaurantMenuCard,
    StarRating,
  },
  mixins: [commonMixin],
  methods: {
    ...mapActions(["getMyOrderCart"]),
    ...mapActions("restaurant", [
      "getRestaurantData",
      "getMenuCategoryArray",
      "filterMenuItems",
    ]),
    ...mapActions("user", [
      "checkReservationNumberValidity",
      "genereateCustomerOtp",
      "checkOtpValidity",
      "checkCustomerInfoValidity",
      "checkCustomerMobile",
      "generateCustomerToken",
      "makeInstantReservation",
      "refreshToken",
      "genereateCustomerValidateOtp",
      "makeInstantRegisteredReservation",
      "genereateCustomerRegisterOtp",
      "checkTableAvailability",
      "resendOtpRequest",
    ]),
    ...mapActions("order", [
      "confirmCustomerOrderItem",
      "createRestaurantRating",
      "createCallWaiterRequest",
      "checkPendingSOSStatus",
      "setSOSStatus",
      "checkActiveReservation",
      "checkReservationHost",
    ]),
    ...mapActions("settings", ["areaList"]),
    resendOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
      };
      this.resendOtpRequest(params).then((data) => {
        if (!data.data.error) {
          this.time = 120;
          this.start();
        } else {
        }
      });
    },
    rateRestaurant() {
      const params = {
        rating: this.rating,
        tenant_id: this.tenant_id,
      };
      this.createRestaurantRating(params).then((data) => {
        this.redirectToMyOrders();
      });
    },
    redirectToMyOrders() {
      this.$router.push({
        name: "MyOrders",
      });
    },
    accountExists(value) {
      if (value) {
        this.customerAccountExist = "Yes";
      } else {
        this.customerAccountExist = "No";
      }
    },
    checkOrderModalShow() {
      const params = {
        active_reservation_id: this.active_reservation_id,
      };
      this.checkActiveReservation(params).then((data) => {
        if (!data.data.error) {
          this.refreshToken().then((data) => {
            if (
              this.hasPermission("CUSTOMER_ORDER") &&
              this.hasPermission(this.tenant_id)
            ) {
              this.showOrderModal = true;
            } else {
              this.$snotify
                .warning("Your ordering session is expired.", "", {
                  position: "centerTop",
                })
                .on("destroyed", () => {});

              this.$router.push({
                path: "/restaurant-menu-view/" + this.tenant_id + "",
              });
            }
          });
        } else {
          this.$snotify
            .warning("Your ordering session is expired.", "", {
              position: "centerTop",
            })
            .on("destroyed", () => {});
          /* clear active reservation data */
          store.commit("user/clearActiveReservationData");
          if (!this.active_reservation_id) {
            store.commit("clearMenuCart");
          }
        }
      });
    },
    openMenuItemModal(item) {
      this.foodDetailsModal = true;
      this.viewMenuItem = item;
    },
    selectVariant(variant, item) {
      this.selectedMenuItem = [];
      this.selectedMenuItem = item;
      this.viewMenuItem = item;
      item.price = variant.menu_item_price;
      item.discount = variant.menu_item_discount;
      item.atr_price = variant.menu_item_price;
      item.atr_discount = variant.menu_item_discount;
      this.active_variation = variant;
      this.active_item_id = item.id;
      item.attribute_id = variant.id;
      variant.selected = 1;

      item.item_attributes.forEach((value, index) => {
        value.attribute_values.forEach((atr_values, index) => {
          if (atr_values.id != variant.id) {
            atr_values.selected = 0;
          } else {
            atr_values.selected = 1;
          }
        });
      });
      console.log(item);
    },
    genereateOtp() {
      //this.genereateValidateOtp();
      if (this.customerAccountExist == "Yes") {
        this.genereateLoginOtp();
      } else {
        this.genereateRegisterOtp();
      }
    },
    genereateValidateOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        first_name: this.first_name,
        last_name: this.last_name,
      };
      this.genereateCustomerValidateOtp(params).then((data) => {
        if (!data.data.error) {
          this.opt_show = true;
          this.mobile_show = false;
          this.start();
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    genereateRegisterOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        account_exists: this.customerAccountExist,
        first_name: this.first_name,
        last_name: this.last_name,
      };
      this.genereateCustomerRegisterOtp(params).then((data) => {
        if (!data.data.error) {
          this.opt_show = true;
          this.mobile_show = false;
          this.start();
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    genereateLoginOtp() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        account_exists: this.customerAccountExist,
      };
      this.checkCustomerMobile(params).then((data) => {
        if (!data.data.error) {
          this.opt_show = true;
          this.mobile_show = false;
          this.start();
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    checkInfoValidity() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        email: this.form_params.email,
        first_name: this.form_params.first_name,
        last_name: this.form_params.last_name,
        refresh_required: false,
      };
      this.checkCustomerInfoValidity(params).then((data) => {
        if (!data.data.error) {
        } else {
          this.$refs.step2_form.setErrors(data.data.error);
        }
      });
    },
    makeNewReservation() {
      const params = {
        mobile_no: this.form_params.mobile_no,
        tenant_id: this.tenant_id,
        table_no: this.$route.query.table_no,
      };
      this.makeInstantRegisteredReservation(params).then((data) => {
        if (!data.data.error) {
          this.$router.push({
            query: Object.assign({}, this.$route.query, {
              reservation_id: data.data.data.active_reservation_id,
            }),
          });
          this.reservation_id = data.data.data.active_reservation_id;
          this.radioValidate = "Yes";
        } else {
          this.$refs.step1_form.setErrors(data.data.error);
        }
      });
    },
    handleOnCompleteRegisterCmp(value) {
      this.form_params.otp = value;
      const params = {
        mobile_no: this.form_params.mobile_no,
        otp: this.form_params.otp,
      };
      this.checkOtpValidity(params).then((data) => {
        if (!data.data.error) {
          this.stop();
          this.vm.isTimerRunning = false;
          clearInterval(this.timer);
          this.vm.timer = null;
          const params = {
            mobile_no: this.form_params.mobile_no,
            tenant_id: this.tenant_id,
            table_no: this.$route.query.table_no,
            email: this.form_params.email,
            first_name: this.first_name,
            last_name: this.last_name,
          };
          this.makeInstantReservation(params).then((data) => {
            if (!data.data.error) {
              this.$router.push({
                query: Object.assign({}, this.$route.query, {
                  reservation_id: data.data.data.active_reservation_id,
                }),
              });
              this.reservation_id = data.data.data.active_reservation_id;
              this.radioValidate = "Yes";
            } else {
              this.$refs.step1_form.setErrors(data.data.error);
            }
          });
        } else {
          this.$refs.step1_checking_form.setErrors(data.data.error);
        }
      });
    },
    start() {
      this.isTimerRunning = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else {
            clearInterval(this.timer);
            this.reset();
          }
        }, 1000);
      }
    },
    stop() {
      this.isTimerRunning = false;
      clearInterval(this.timer);
      this.timer = null;
    },
    reset() {
      this.stop();
      this.time = 0;
      this.secondes = 0;
      this.minutes = 0;
      this.isTimerRunning = false;
      this.timer = null;
    },
    setTime(payload) {
      this.time = payload.minutes * 60 + payload.secondes;
    },
    /* validate customer enter rservation number is valid */
    handleOnComplete(value) {
      this.reservation_number = value;
      const params = {
        reservation_number: value,
        tenant_id: this.tenant_id,
        table_no: this.$route.query.table_no,
      };
      this.checkReservationNumberValidity(params).then((data) => {
        if (!data.data.error) {
          this.validateReservationNumberModal = false;
          const params = {
            active_reservation_id: this.active_reservation_id,
          };
          this.initiateNotifications();
          this.getMyOrderCart(params).then((data) => {
            if (!data.data.error) {
            }
          });
          this.checkActualResrvationHost();
        } else {
          this.$refs.reservation_number_checking_form.setErrors(
            data.data.error
          );
        }
      });
    },
    reservationChecked(val) {
      this.radioValidate = val;
    },
    addTocart() {
      let orderItem = {
        item_id: this.selectedMenuItem.id,
        item_name: this.selectedMenuItem.title,
        count: this.menuItemCount,
        item_amount: this.selectedMenuItem.price,
        total_amount: this.selectedMenuItem.price * this.menuItemCount,
        amount: this.selectedMenuItem.price,
        item_discount: this.selectedMenuItem.discount,
        order_comment: this.order_comment,
        attribute_id: this.selectedMenuItem.attribute_id,
      };
      this.$store.state.menuItemCart.items.push(orderItem);

      let order_total = Object.values(
        this.$store.state.menuItemCart.items
      ).reduce((a, { total_amount }) => {
        return a + total_amount;
      }, 0);
      let tenant_id = this.$route.params.tenant_id;
      this.tenant_id = tenant_id;
      this.$store.state.menuItemCart.tenant_id = tenant_id;
      this.$store.state.menuItemCart.order_total = order_total;

      store.commit("setMenuCart", this.$store.state.menuItemCart);

      this.selectedMenuItem = [];
      this.menuItemCount = 1;
      this.order_comment = "";
    },
    incrementMenuItemCount() {
      this.menuItemCount++;
    },
    decrementMenuItemCount() {
      if (this.menuItemCount > 1) {
        this.menuItemCount--;
      }
    },
    setMenuItem(item) {
      this.selectedMenuItem = item;
      if (
        item.item_attributes_filter &&
        item.item_attributes_filter[0].attribute_values[0]
      ) {
        if (this.active_variation && this.active_item_id == item.id) {
          this.selectVariant(this.active_variation, item);
        } else {
          this.selectVariant(
            item.item_attributes_filter[0].attribute_values[0],
            item
          );
        }
      }
    },
    confirmOrderItem() {
      let orderItem = {
        item_id: this.selectedMenuItem.id,
        item_name: this.selectedMenuItem.title,
        count: this.menuItemCount,
        item_amount: this.selectedMenuItem.price,
        total_amount: this.selectedMenuItem.price * this.menuItemCount,
        amount: this.selectedMenuItem.price,
        item_discount: this.selectedMenuItem.discount,
        order_comment: this.order_comment,
        attribute_id: this.selectedMenuItem.attribute_id,
        active_reservation_id: this.active_reservation_id,
      };
      this.confirmCustomerOrderItem(orderItem).then((data) => {
        if (!data.data.error) {
          this.refreshOrderCart();
        } else {
        }
        this.showOrderModal = false;
        this.menuItemCount = 1;
      });
    },
    mapRedirect(restaurant) {
      let location = JSON.parse(restaurant.location);
      let route = this.$router.resolve({
        path: "/pages/map?lat=" + location.lat + "&lng=" + location.lng + "",
      });
      window.open(route.href, "_blank");
    },
    setActivebtn(meal_type) {
      this.isLoading = true;
      this.$store.state.activeMealTypeBtn = meal_type;
      const params = {
        meal_type: meal_type,
        tenant_id: this.tenant_id,
      };
      this.filterMenuItems(params).then((data) => {
        if (!data.data.error) {
          this.restaurantMenuCategory = data.data;
        }
        this.isLoading = false;
      });
      this.$store.state.activeMenuCategoryId = "";
    },
    getDynamicColor(meal_type) {
      if (this.$store.state.activeMealTypeBtn == meal_type) {
        return "primary";
      } else {
        return "light";
      }
    },
    getDynamicCategoryBtnColor(categoryId) {
      if (this.$store.state.activeMenuCategoryId == categoryId) {
        return "primary";
      } else {
        return "light";
      }
    },
    setActiveMenuCategoryBtn(categoryId) {
      this.$store.state.activeMenuCategoryId = categoryId;
    },
    getDynamimcCategoryDIVRef(categoryId) {
      return "category_div_" + categoryId;
    },
    checkValidInput(error) {
      if (error.length) {
        return false;
      }
    },
    refreshOrderCart() {
      if (this.active_reservation_id) {
        const params = {
          active_reservation_id: this.active_reservation_id,
        };

        this.getMyOrderCart(params).then((data) => {
          if (!data.data.error) {
          }
        });
      }
    },

    //waiter reuquest for the active table reservation
    async callWaiter() {
      let data = {
        tenant_id: this.tenant_id,
      };
      await this.createCallWaiterRequest(data); // send SOS request to waiter
    },

    //enable sos button is no pending requests
    async checkSOSStatus() {
      let request_data = {
        tenant_id: this.tenant_id,
        customer_id: this.customer_id,
      };
      this.checkPendingSOSStatus(request_data);
    },

    initiateNotifications() {
      var that = this;
      if (localStorage.getItem("customer_access_token")) {
        window.Echo = new Echo({
          broadcaster: process.env.VUE_APP_WEBSOCKETS_BROADCASTER,
          key: process.env.VUE_APP_WEBSOCKETS_KEY,
          wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
          wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
          cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
          forceTLS: false,
          disableStats: true,
          authEndpoint:
            "" + process.env.VUE_APP_API_BASE_URL + "broadcasting/auth",
        });
        window.Echo.connector.options.auth.headers[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("customer_access_token")}`;
      }

      window.Echo.private(`order_closed.${that.user_id}`) // *
        .listen("OrderClose", function (e) {
          store.commit("user/clearActiveReservationData");
          if (!that.active_reservation_id) {
            store.commit("clearMenuCart");
          }
          that.$snotify
            .success("Your Order Closed", "Success!", {
              position: "centerTop",
            })
            .on("destroyed", () => {
              that.refreshToken().then((data) => {
                that.orderRatingModal = true;
                if (!data.data.error) {
                }
              });
            });
        });

      window.Echo.private(`order_status_changed.${that.user_id}`) // *
        .listen("OrderStatusChange", function (e) {
          if (that.active_reservation_id) {
            const params = {
              active_reservation_id: that.active_reservation_id,
            };
            that.getMyOrderCart(params).then((data) => {
              if (!data.data.error) {
              }
            });
          }
        });

      window.Echo.private(`order_add_by_waiter.${that.user_id}`) // *
        .listen("OrderAddByWaiter", function (e) {
          if (that.active_reservation_id) {
            const params = {
              active_reservation_id: that.active_reservation_id,
            };
            that.getMyOrderCart(params).then((data) => {
              if (!data.data.error) {
              }
            });
          }
        });
    },

    checkActualResrvationHost() {
      const resParams = {
        active_reservation_id: this.active_reservation_id,
      };
      this.checkReservationHost(resParams).then((data) => {
        if (!data.data.error) {
          this.isReservationHost = true;
        } else {
        }
      });
    },
  },
  watch: {
    otp_mask: {
      handler: function (after, before) {
        let otpStr = this.otp_mask;
        if (otpStr.length == 7) {
          otpStr = otpStr.replace(/-/g, "");
          this.handleOnComplete(otpStr);
        }
      },
      deep: true,
    },
    otp_reservation_mask: {
      handler: function (after, before) {
        let otpStr = this.otp_reservation_mask;
        if (otpStr.length == 7) {
          otpStr = otpStr.replace(/-/g, "");
          this.handleOnCompleteRegisterCmp(otpStr);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.vm = this;
    let tenant_id = this.$route.params.tenant_id;
    this.reservation_id = this.$route.query.reservation_id;

    if (this.customer_access_token && this.active_reservation_id) {
      this.setSOSStatus(); //enable sos button is no pending requests
    }

    let token_validate = this.$route.query.token_validate;
    if (
      (token_validate && !this.hasPermission("CUSTOMER_ORDER")) ||
      (token_validate && !this.active_reservation_id)
    ) {
      this.validateReservationNumberModal = true;
    }

    this.tenant_id = tenant_id;
    const params = {
      tenant_id: tenant_id,
      meal_type: "all",
    };

    this.getRestaurantData(params).then((data) => {
      if (!data.data.error) {
        this.restaurant_name = data.data.restaurant_name;
        this.menuItems = data.data.menu_items;
        this.menuSliders = data.data.image_collection;
        this.restaurantData = data.data;
        this.menuBackgroundImage = data.data.menu_back_image_collection[0].url;

        this.$store.state.service_charge = data.data.service_charge;
        store.commit("setMenuCart", this.$store.state.menuItemCart);
      }
    });

    this.getMenuCategoryArray(params).then((data) => {
      if (!data.data.error) {
        this.options = data.data;
      }
    });

    if (this.customer_access_token) {
      this.refreshOrderCart();
    }

    this.filterMenuItems(params).then((data) => {
      if (!data.data.error) {
        this.restaurantMenuCategory = data.data;
        if (this.restaurantMenuCategory[0]["id"]) {
          this.$store.state.activeMenuCategoryId =
            this.restaurantMenuCategory[0]["id"];
        }
      }
    });

    const table_params = {
      tenant_id: tenant_id,
      table_no: this.$route.query.table_no,
    };
    this.checkTableAvailability(table_params).then((data) => {
      if (!data.data.error) {
        this.check_table_availability = data.data;
      }
      this.check_table_availability_finish = true;
    });

    if (this.customer_access_token && this.active_reservation_id) {
      let request_data = {
        tenant_id: this.tenant_id,
        customer_id: this.customer_id,
      };

      this.checkPendingSOSStatus(request_data);
    }

    if (this.customer_access_token && this.active_reservation_id) {
      //check reservation host
      this.checkActualResrvationHost();
    }
  },
  data() {
    return {
      value: null,
      options: [
        {
          id: "a",
          label: "a",
          children: [
            {
              id: "aa",
              label: "aa",
            },
            {
              id: "ab",
              label: "ab",
            },
          ],
        },
        {
          id: "b",
          label: "b",
        },
        {
          id: "c",
          label: "c",
        },
      ],
      restaurant_name: "",
      menuItems: [],
      menuSliders: [],
      menuBackgroundImage: "",
      restaurantData: [],
      tenant_id: "",
      restaurantMenuCategory: [],
      isLoading: false,
      fullPage: true,
      showOrderModal: false,
      selectedMenuItem: [],
      menuItemCount: 1,
      menuItemCart: [],
      order_comment: "",
      validateReservationNumberModal: false,
      reservation_no_have: "",
      radioValidate: "Yes",
      reservation_number: "",
      customerAccountExist: "Yes",
      otp_mask: "",
      otp_reservation_mask: "",
      form_params: {
        mobile_no: "",
        first_name: "",
        last_name: "",
        email: "",
        otp: "",
      },
      opt_show: false,
      mobile_show: true,
      vm: "",
      isTimerRunning: false,
      minutes: 2,
      secondes: 0,
      time: 120,
      timer: null,
      reservation_id: "",
      active_variation: "",
      active_item_id: "",
      account_exists: true,
      foodDetailsModal: false,
      viewMenuItem: [],
      first_name: "",
      last_name: "",
      customer_id: localStorage.getItem("user_id"),
      rating: 0,
      show_rating: false,
      check_table_availability: false,
      check_table_availability_finish: false,
      isReservationHost: false,
    };
  },
  computed: {
    ...mapState("user", [
      "user_name",
      "customer_access_token",
      "active_reservation_id",
      "user_id",
    ]),
    ...mapState("order", ["SOSDisable"]),
    ...mapGetters("user", ["loggedIn"]),
    orderRatingModal: {
      get() {
        return this.$store.state.orderRatingModal;
      },
      set(value) {
        this.$store.state.orderRatingModal = value;
      },
    },
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },
  },
  created() {
    this.initiateNotifications();
  },
};
</script>

<style scoped>
.varient-btns{
  padding: 0.15rem 0.5rem;
  font-size: 0.666rem;
  line-height: 1.5;
  margin: 0.08rem;
}
.menu-item-description-txt{
  font-size: 11px;
}
.menu-item-price-txt{
  color: #5449b8;
  font-weight: bold;
  font-size: 14px;
}
.see-all-link{
  font-size: 11px;
}
.menu-container {
  background-color: #ffffff;
  margin: 10px;
}
.custom-dashed{
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.custom-menu-img {
  object-fit: cover;
  width: 100%;
  height: 150px;
  padding: 7px;
}

.custom-menu-card {
  margin: 10px;
  border-color: #d7c1c1;
}

.menu-img {
  object-fit: cover;
  width: 100%;
  height: 150px;
}

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&display=swap");
.min-height-desc {
  min-height: 30px;
}

.font-family {
  font-family: "Dancing Script", cursive;
}

.rating-arrangment {
  float: right;
}

.restaurant-img {
  object-fit: cover;
  width: 100%;
  height: 150px;
  border-radius: 1.5rem 1.5rem 0rem 0rem !important;
}

.card-title,
.location-text,
.address-text,
.description-text,
.min-height-desc,
.discount-text {
  color: #ffffff;
}

.category-color {
  background-color: rgba(255, 255, 255, 0.493);
  color: #ffffff;
  padding: 0.5rem 1.25rem;
}

.location-color {
  color: blueviolet;
}

.rest-bg-imge {
  /* background-image: url(“/img/bg_images/rest-img.jpg”); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
}

.resturent-main-card {
  background-color: rgba(0, 0, 0, 0.514);
  min-height: 90vh;
}

.food-item-scroll {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.scrolling-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.card-item {
  max-width: 25%;
  padding: 0.75rem;
  /* margin-bottom: 2rem; */
  border: 0;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 330px;
}

.rating-arrangment {
  float: right;
}

.min-height-card {
  min-height: 250px;
  max-height: 250px;
  border-radius: 1.5rem !important;
  /* border: 0.1rem solid #FFFFFF ; */
  background-color: rgba(0, 0, 0, 0.822);
  padding-bottom: 0.8rem;
  box-shadow: rgb(28 28 28 / 12%) 0px 0.4rem 1.8rem;
  min-width: 23vw;
}

.empty-food-row {
  min-height: 250px;
  max-height: 250px;
}

.card-border {
  /* border: 1px solid #FFFFFF !important; */
}

hr.dashed {
  border-top: 0.12rem dashed #bbb;
}

@media (max-width: 575.98px) {
  .min-height-card {
    margin-bottom: 1px !important;
  }
  .scrolling-wrapper {
    flex-direction: column !important;
    max-height: 600px;
  }
  .card-item {
    max-width: 100%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 330px;
  }
  .mobile-view {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .mobile-space {
    height: 70vh;
  }
}

@media (max-width: 768px) {
  .tree-select {
    padding-top: 20px;
  }

  .meal-plan {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .card-item {
    max-width: 50%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 330px;
  }
  .mobile-space {
    height: 70vh;
  }
}

@media (min-width: 2500px) {
  .card-item {
    max-width: 25%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 330px;
  }
}
</style>
<style>
.custom-wizard-menu .wizard-header {
  display: none;
}

.custom-wizard-menu .category {
  font-size: 35px;
  font-weight: bold;
}

.custom-wizard-menu .wizard-card-footer .wizard-footer-right {
  float: right;
  width: 100%;
}

.finish-button {
  padding: 18px !important;
}

.wizard-progress-with-circle {
  display: none;
}

.wizard-nav.wizard-nav-pills {
  display: none;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 14px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: "center";
}

.error {
  border: 1px solid red !important;
}

.container-fluid {
  max-width: 100%;
  padding-right: 0rem !important;
  padding-left: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.c-main {
  padding-top: 0rem !important;
  margin-bottom: -28px !important;
  /* remove padding will display whitel layers in bottom  */
}

.modal-dark .modal-header {
  color: #fff;
  background-color: #111111 !important;
  border: 1px solid #171718 !important;
}

.modal-body {
  background-color: #171718 !important;
  border: 1px solid #171718 !important;
}

.modal-footer {
  background-color: #171718 !important;
  border: 1px solid #171718 !important;
}

.form-control {
  background-color: #171718 !important;
  border: 1px solid #ffffff !important;
}
</style>

<style scoped>
.sub-category-btn {
  white-space: nowrap;
  text-align: center;
}

.min-height-desc {
  min-height: 30px;
}

.rating-arrangment {
  float: right;
}

.restaurant-img {
  object-fit: cover;
  width: 100%;
  height: 150px;
  border-radius: 1.5rem 1.5rem 0rem 0rem !important;
}

.card-title,
.location-text,
.address-text,
.description-text,
.min-height-desc,
.discount-text {
  color: #ffffff;
}

.location-color {
  color: blueviolet;
}

.rest-bg-imge {
  /* background-image: url("/img/bg_images/rest-img.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.food-item-scroll {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.scrolling-wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.card-item {
  max-width: 25%;
  padding: 0.75rem;
  /* margin-bottom: 2rem; */
  border: 0;
  flex-basis: 25%;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 250px;
}

.rating-arrangment {
  float: right;
}

.min-height-card {
  min-height: 250px;
  max-height: 250px;
  border-radius: 1.5rem !important;
  /* border: 0.1rem solid #ffffff ; */
  background-color: rgba(0, 0, 0, 0.822);
  padding-bottom: 0.8rem;
  box-shadow: rgb(28 28 28 / 12%) 0px 0.4rem 1.8rem;
  min-width: 23vw;
}

.resturent-main-card {
  background-color: rgba(0, 0, 0, 0.514);
}

.card-border {
  /* border: 1px solid #ffffff !important; */
}

hr.dashed {
  border-top: 0.12rem dashed #bbb;
}

@media (max-width: 575.98px) {
  .card-item {
    max-width: 100%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 250px;
  }
}

@media (max-width: 768px) {
  .tree-select {
    padding-top: 20px;
  }

  .meal-plan {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .card-item {
    max-width: 50%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 250px;
  }
}

@media (min-width: 2500px) {
  .card-item {
    max-width: 25%;
    padding: 0.75rem;
    /* margin-bottom: 2rem; */
    border: 0;
    flex-basis: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 250px;
  }
}
</style>
