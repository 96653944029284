<template>
  <div>
    <RestaurantMenuViewDev1/>
  </div>
</template>

<script>
import RestaurantMenuViewDev1 from "@/component/RestaurantMenuView/RestaurantMenuViewDev.vue";

export default {
  name: 'RestaurantMenuViewDev',
  components: {
    RestaurantMenuViewDev1,
  }
}
</script>

<style scoped>



</style>
